import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'

import BaseModal from 'components/BaseModal'
import Button from 'components/Button'
import { Body } from 'components/Text'
import ROUTES from 'constants/routes'
import Connector from 'containers/Connector'
import localStore from 'utils/localStore'
import logError from 'utils/logError'
import Logo from 'assets/svg/brand/dextoro-logo.svg'
import LogoLight from 'assets/svg/brand/dextoro-logo-light.svg'
import { useWalkthroughContext } from 'contexts/WalkthroughContext'
import { BASE_URL } from './Walkthrough'

export default function AcknowledgementModal() {
	const { walletAddress } = Connector.useContainer()
	const router = useRouter()
	const [acks, setAcks] = useState<Record<string, boolean>>({})
	const { setNewTrader } = useWalkthroughContext()
	const currentTheme = useAppSelector(selectCurrentTheme)

	const DextoroLogo = useMemo(
		() => (currentTheme === 'light' ? <DextoroLightLogo /> : <DextoroDarkLogo />),
		[currentTheme]
	)

	const acknowledgedAddresses = (localStore.get('acknowledgedAddresses') || {}) as Record<
		string,
		boolean
	>

	const protectedRoute =
		router.asPath.startsWith(ROUTES.Earn.Home) ||
		router.asPath.startsWith(ROUTES.Exchange.Home) ||
		router.asPath.includes('/market')

	if (
		!walletAddress ||
		acks[walletAddress.toLowerCase()] ||
		acknowledgedAddresses[walletAddress.toLowerCase()]
	) {
		return null
	}

	const onAccept = async () => {
		try {
			acknowledgedAddresses[walletAddress.toLowerCase()] = true
			localStore.set('acknowledgedAddresses', acknowledgedAddresses)
			await axios
				.post(`${BASE_URL}?walletAddress=${walletAddress.toLowerCase()}`)
				.then((res) => setNewTrader(res.data?.walletAddress))
			setAcks({ ...acks, [walletAddress.toLowerCase()]: true })
		} catch (err) {
			logError(err)
		}
	}

	return (
		<StyledBaseModal onDismiss={() => {}} title="Terms of Service" showCross={false}>
			<LogoContainer>{DextoroLogo}</LogoContainer>
			<BodyText>
				Welcome to DexToro, a decentralized derivatives exchange that aims to promote financial
				equality through accessible investment tools managed by an international community.
				<br />
				<br />
				Please note that DexToro may not be suitable for use in all legal jurisdictions. By using
				our platform, you confirm that you have thoroughly investigated your legal situation and
				sought guidance from a legal representative in your jurisdiction, if necessary.
				<br />
				<br />
				Please ensure that you understand your local regulations well enough to determine whether
				you are authorized to use DexToro, as our reliance on smart contracts and blockchain
				technology means that we cannot block anyone from accessing the protocol. All users are
				responsible for their own actions.
				<br />
				<br />
			</BodyText>
			<Button variant="flat" size="medium" onClick={onAccept}>
				Accept & Continue
			</Button>
		</StyledBaseModal>
	)
}

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		max-width: 400px;
	}
	.card > div:first-child {
		margin-bottom: 12px;
		justify-content: center;
	}
`

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 12px;
`

const DextoroDarkLogo = styled(Logo)`
	width: 200px;
	height: 25px;
	overflow: visible;
`

const DextoroLightLogo = styled(LogoLight)`
	width: 200px;
	height: 20px;
`

const BodyText = styled(Body)`
	font-size: 16px;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
`
