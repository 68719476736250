import { FuturesMarketAsset } from '@dextoroprotocol/sdk/types'

import { AppFuturesMarginType } from 'state/futures/common/types'

export const formatUrl = (route: string, params: Record<string, string>) => {
	return route + '?' + new URLSearchParams(params)
}

export const ROUTES = {
	Home: {
		Root: '/portfolio',
		MainSite: 'https://dextoro.com/',
		Buy: 'https://www.coingecko.com/en/coins/dextoro',
	},
	Portfolio: {
		Home: '/portfolio',
		History: '/portfolio/history',
		Markets: '/portfolio/markets',
		Fees: '/portfolio/fees',
		Positions: '/portfolio/positions',
		Orders: '/portfolio/orders',
	},
	Markets: {
		Home: (accountType: AppFuturesMarginType) =>
			formatUrl('/market', { accountType, asset: 'sETH' }),
		MarketPair: (asset: FuturesMarketAsset | string, _: AppFuturesMarginType | string) =>
			formatUrl('/market', { asset, accountType: 'smart_wallet' }),
		Position: (asset: FuturesMarketAsset, _: AppFuturesMarginType | string) =>
			formatUrl('/market', {
				asset,
				accountType: 'smart_wallet',
				tab: 'position',
			}),
		Orders: (asset: FuturesMarketAsset, _: AppFuturesMarginType | string) =>
			formatUrl('/market', { asset, accountType: 'smart_wallet', tab: 'orders' }),
		ConditionalOrders: (asset: FuturesMarketAsset, _: AppFuturesMarginType | string) =>
			formatUrl('/market', { asset, accountType: 'smart_wallet', tab: 'conditional_orders' }),
		Trades: (asset: FuturesMarketAsset, _: AppFuturesMarginType | string) =>
			formatUrl('/market', { asset, accountType: 'smart_wallet', tab: 'trades' }),
		Transfers: (asset: FuturesMarketAsset, _: AppFuturesMarginType | string) =>
			formatUrl('/market', { asset, accountType: 'smart_wallet', tab: 'transfers' }),
	},
	Exchange: {
		Home: '/exchange',
		MarketPair: (baseCurrencyKey: string, quoteCurrencyKey: string) =>
			`/exchange/?quote=${quoteCurrencyKey}&base=${baseCurrencyKey}`,
		Into: (currencyKey: string) => `/exchange/?quote=${currencyKey}`,
	},
	Leaderboard: {
		Home: '/leaderboard',
		Trader: (trader: string) => `/leaderboard/?trader=${trader}`,
		Competition: (round: string) => `/leaderboard/?competitionRound=${round}`,
	},
	Staking: {
		Home: '/staking-rewards',
	},
	Referrals: {
		Home: '/referrals',
	},
	Profile: {
		Home: '/profile',
	},
	Stats: {
		Home: '/stats',
	},
	Earn: {
		Home: '/earn',
	},
}

export const setLastVisited = (
	baseCurrencyPair: string,
	accountType: AppFuturesMarginType
): void => {
	localStorage.setItem('lastVisited', ROUTES.Markets.MarketPair(baseCurrencyPair, accountType))
}

export default ROUTES
