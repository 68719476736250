import React, { useState } from 'react'
import styled from 'styled-components'
import { useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit'
import Link from 'next/link'
import { wei } from '@synthetixio/wei'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import useClickOutside from 'hooks/useClickOutside'
import { setDefaultTab, setOpenModal } from 'state/app/reducer'
import { selectShowModal } from 'state/app/selectors'
import {
	selectDelegated,
	selectSessionActivated,
	selectSmartAccountBalance,
} from 'state/oneClickTrading/selectors'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { setTheme } from 'state/preferences/reducer'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'

import media from 'styles/media'
import Button from 'components/Button'
import { DEFAULT_TX_FEE } from 'constants/defaults'
import ManageSession from 'sections/futures/ManageSmartMarginAccount/OneClickTrading/ManageSession'
import Connector from 'containers/Connector'
import CopyToClipboardButton from 'components/Button/CopyToClipboardButton'

import Etherscan from 'assets/svg/app/etherscan.svg'
import LinkIcon from 'assets/svg/app/link-new.svg'
import LightOnIcon from 'assets/svg/app/light-on.svg'
import LightOffIcon from 'assets/svg/app/light-off.svg'
import DepositIcon from 'assets/svg/app/deposit-icon.svg'
import DisconnectIcon from 'assets/svg/app/disconnect-icon.svg'
import MoonIcon from 'assets/svg/app/moon.svg'
import SunIcon from 'assets/svg/app/sun.svg'
import SwitchIcon from 'assets/svg/app/blockchain.svg'
import SecureIcon from 'assets/svg/app/secure.svg'
import DropdownArrow from 'assets/svg/app/dropdown-arrow.svg'
import { MobileHiddenView, MobileOnlyView } from 'components/Media'
import useLocalStorage from 'hooks/useLocalStorage'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'
import OneClickTradingModal from './OneClickTradingModal'

const Settings: React.FC<{ onDismiss: () => void }> = ({ onDismiss }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { openAccountModal } = useAccountModal()
	const { openConnectModal } = useConnectModal()
	const { openChainModal } = useChainModal()
	const { isWalletConnected, walletAddress } = Connector.useContainer()
	const delegated = useAppSelector(selectDelegated)
	const showModal = useAppSelector(selectShowModal)
	const openOneClickModal = showModal === 'oneclick_active_delegate'
	const isSessionActivated = useAppSelector(selectSessionActivated)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const isLowBalance = wei(smartAccountBalance).lt(DEFAULT_TX_FEE)

	const [OCT, setOCT] = useState(false)
	const { ref } = useClickOutside(() => setOCT(false))

	const currentTheme = useAppSelector(selectCurrentTheme)
	const ThemeIcon = currentTheme === 'dark' ? SunIcon : MoonIcon

	const toggleTheme = () => {
		dispatch(setTheme(currentTheme === 'light' ? 'dark' : 'light'))
		onDismiss()
	}

	const onCopied = () => setTimeout(() => onDismiss(), 1000)

	const LightIcon = isSessionActivated ? LightOnIcon : LightOffIcon

	const handleOpenModal = () => {
		dispatch(setOpenModal('oneclick_active_delegate'))
		setOCT(false)
	}

	const handleCloseModal = () => {
		dispatch(setOpenModal(null))
		onDismiss()
	}

	const openDepositModal = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('deposit'))
		onDismiss()
	}

	const handleDeposit = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('oneclick-trading'))
		setOCT(false)
		onDismiss()
	}

	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	return (
		<>
			<MobileHiddenView>
				{!openOneClickModal && <Background onClick={() => onDismiss()} />}
				<Dialog>
					<Container>
						<List onClick={isWalletConnected ? openDepositModal : openConnect}>
							<DepositIcon /> Deposit
						</List>
						<List onClick={() => setOCT(!OCT)}>
							<LightIcon />
							One Click Trading
						</List>
						<>
							{OCT && (
								<OctContainer>
									{delegated ? (
										<>
											<ManageSession isMenu onDismiss={onDismiss} />
											{isLowBalance && (
												<DepositButton onClick={handleDeposit}>
													{t('dashboard.oneclick.fund.deposit')}
												</DepositButton>
											)}
										</>
									) : (
										<ActivateButton onClick={handleOpenModal}>
											{t('dashboard.oneclick.manage.activate-button')}
										</ActivateButton>
									)}
								</OctContainer>
							)}
							<OneClickTradingModal isOpenModal={openOneClickModal} onDismiss={handleCloseModal} />
						</>
						<List onClick={openChainModal}>
							<SwitchIcon /> <span onClick={onDismiss}>Switch Networks</span>
						</List>
						<List onClick={onCopied}>
							<CopyToClipboardButton text={walletAddress!} label="Copy address" />
						</List>
						<List>
							<Link
								href={`https://optimistic.etherscan.io/address/${walletAddress}`}
								target="_blank"
								rel="noopener"
								onClick={onDismiss}
							>
								<Etherscan /> Open in Etherscan <LinkIcon />
							</Link>
						</List>
						{smartMarginAccount && (
							<List>
								<Link
									href={`https://optimistic.etherscan.io/address/${smartMarginAccount}`}
									target="_blank"
									rel="noopener"
									onClick={onDismiss}
								>
									<SecureIcon /> Smart Wallet
									<LinkIcon />
								</Link>
							</List>
						)}
						<List onClick={toggleTheme}>
							<ThemeIcon height={14} />
							Change theme
						</List>
						<Divider />
						<List alert onClick={openAccountModal}>
							<DisconnectIcon /> <span onClick={onDismiss}>Disconnect</span>
						</List>
					</Container>
				</Dialog>
			</MobileHiddenView>
			<MobileOnlyView>
				<Dialog>
					<MobileContainer>
						<List onClick={isWalletConnected ? openDepositModal : openConnect}>
							<DepositIcon /> Deposit
						</List>
						<List onClick={() => setOCT(!OCT)}>
							<LightIcon />
							One Click Trading
						</List>
						<>
							{OCT && (
								<OctContainer>
									{delegated ? (
										<>
											<ManageSession isMenu />
											{isLowBalance && (
												<DepositButton onClick={handleDeposit}>
													{t('dashboard.oneclick.fund.deposit')}
												</DepositButton>
											)}
										</>
									) : (
										<ActivateButton onClick={handleOpenModal}>
											{t('dashboard.oneclick.manage.activate-button')}
										</ActivateButton>
									)}
								</OctContainer>
							)}
							<OneClickTradingModal isOpenModal={openOneClickModal} onDismiss={handleCloseModal} />
						</>
						<List onClick={openChainModal}>
							<SwitchIcon /> <span onClick={onDismiss}>Switch Networks</span>
						</List>
						<List onClick={onCopied}>
							<CopyToClipboardButton text={walletAddress!} label="Copy address" />
						</List>
						<List>
							<Link
								href={`https://optimistic.etherscan.io/address/${walletAddress}`}
								target="_blank"
								rel="noopener"
								onClick={onDismiss}
							>
								<Etherscan /> Open in Etherscan <LinkIcon />
							</Link>
						</List>
						{smartMarginAccount && (
							<List>
								<Link
									href={`https://optimistic.etherscan.io/address/${smartMarginAccount}`}
									target="_blank"
									rel="noopener"
									onClick={onDismiss}
								>
									<SecureIcon /> Smart Wallet
									<LinkIcon />
								</Link>
							</List>
						)}
						<List onClick={toggleTheme}>
							<ThemeIcon height={14} />
							Change theme
						</List>
						<Divider />
						<List alert onClick={openAccountModal}>
							<DisconnectIcon /> <span onClick={onDismiss}>Disconnect</span>
						</List>
					</MobileContainer>
				</Dialog>
			</MobileOnlyView>
		</>
	)
}

export default Settings

const Dialog = styled.div`
	position: relative;
	z-index: 1001;
`

const Background = styled.div`
	z-index: 999;
	position: fixed;
	top: 44px;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(8px);
	right: 0;
`

const Container = styled.div`
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	position: absolute;
	right: 0;
	top: 32px;
	padding: 12px 20px;
	min-width: 224px;
	border-radius: 8px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	background-color: ${(props) => props.theme.colors.selectedTheme.gray3};
	font-family: ${(props) => props.theme.fonts.regular};
`

const MobileContainer = styled.div`
	font-size: 17px;
	line-height: 20px;
	top: auto;
	bottom: 24px;
	right: 0;
	min-width: 264px;
	position: absolute;
	padding: 12px 20px;
	border-radius: 8px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	background-color: ${(props) => props.theme.colors.selectedTheme.gray3};
	font-family: ${(props) => props.theme.fonts.regular};

	p {
		font-size: 17px;
		line-height: 20px;
	}
`

const List = styled.div<{ alert?: boolean }>`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 8px 0;
	white-space: nowrap;
	transition: all 0.15s ease-in-out !important;

	svg {
		max-height: 20px;
		width: 16px;
		margin-right: 12px;
	}

	svg:nth-child(2) {
		margin-left: 4px;
		width: 14px;
	}

	button {
		justify-content: flex-start;
		padding: 0;
		gap: 0;

		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.gray};
			}
		}

		p {
			color: ${(props) => props.theme.colors.selectedTheme.gray};
		}
	}

	a {
		display: flex;
		align-items: center;
		color: ${(props) => props.theme.colors.selectedTheme.gray};
	}

	&:hover {
		color: ${(props) =>
			props.alert
				? props.theme.colors.selectedTheme.red
				: props.theme.colors.selectedTheme.newTheme.text.primary};
		svg {
			path {
				stroke: ${(props) =>
					props.alert
						? props.theme.colors.selectedTheme.red
						: props.theme.colors.selectedTheme.newTheme.text.primary};
			}
		}

		svg:nth-child(2) {
			path {
				fill: none;
			}
		}

		button {
			p {
				color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
			}

			svg {
				path {
					fill: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
				}
			}
		}

		a {
			color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};

			svg {
				path {
					fill: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
				}
			}
		}
	}
`

const Divider = styled.hr`
	border: ${(props) => props.theme.colors.selectedTheme.border};
`

const OctContainer = styled.div`
	z-index: 100;
	position: absolute;
	right: 100%;
	top: 1px;
	background: ${(props) => props.theme.colors.selectedTheme.gray3};
	padding: 16px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 8px;

	${media.greaterThan('mdUp')`
		margin-top: 56px;
	`};

	${media.lessThan('md')`
		bottom: 0;
		right: 0;
		top: auto;
		width: 100%;
		min-height: 90px;
		border-radius: 12px 12px 0 0;
	`}
`

const ActivateButton = styled(Button)`
	background: ${(props) => props.theme.colors.selectedTheme.button.dextoroPrimary.active};
	font-size: 14px;

	${media.lessThan('md')`
		width: 100%;
	`}
`

const DepositButton = styled(Button)`
	width: 100%;
	margin-top: 16px;
`

// & > div:first-child {
// 	padding: 0;
// 	right: 0;
// }
